import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useWorkspaceDetails } from '../../../../hooks'
import { smartFunctionService } from '../../../../services'
import workspaceService from '../../../../services/workspaceService'
import {
  AlgorithmRun,
  RiskReliefConfig,
} from '../../../../store/reducers/workspaceReducer'
import WorkspaceStatistic from './WorkspaceStatistic'
import WorkspaceStatisticsPanelContainer from './WorkspaceStatisticsPanelContainer'

interface RiskReliefDeviationStatistics {
  totalRuns?: number
  numberOfDeviations?: number
}

const RiskReliefDeviationStatisticsPanel = () => {
  const dispatch = useDispatch()
  const { deviationId } = useParams()
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })
  const [statistics, setStatistics] = useState<RiskReliefDeviationStatistics>()

  const calculateStatistics = useCallback(
    (res: AlgorithmRun) => {
      const totalRuns = selectedWorkspace?.algorithmRuns?.filter((run) => {
        const runConfig = run.config as RiskReliefConfig
        return runConfig?.deviationId === deviationId
      }).length

      const numberOfDeviations =
        selectedWorkspace?.config?.riskReliefConfig?.deviations?.filter(
          (deviation) => deviation.searchQueryId === deviationId
        ).length

      return {
        totalRuns,
        numberOfDeviations,
      }
    },
    [selectedWorkspace, deviationId]
  )

  useEffect(() => {
    const mostRecentCompleteAlgorithmRun =
      workspaceService.getMostRecentCompletedAlgorithmRun({
        workspace: selectedWorkspace,
      })

    if (mostRecentCompleteAlgorithmRun && selectedWorkspace?.id) {
      smartFunctionService
        .getWorkspaceAlgorithmRun({
          workspaceId: selectedWorkspace?.id,
          algorithmRunId: mostRecentCompleteAlgorithmRun.id,
        })
        .then((res) => {
          const values = calculateStatistics(res)
          setStatistics(values)
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [selectedWorkspace, calculateStatistics, dispatch])

  return (
    <WorkspaceStatisticsPanelContainer>
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.riskReleifDeviationStatistics.smartFunctionRuns'
        )}
        value={statistics?.totalRuns || 0}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.riskReleifDeviationStatistics.deviationInclusions'
        )}
        value={statistics?.numberOfDeviations || 0}
      />
    </WorkspaceStatisticsPanelContainer>
  )
}

export default RiskReliefDeviationStatisticsPanel
