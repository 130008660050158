import { t } from 'i18next'
import SimpleSwitch from './SimpleSwitch'
import SimpleSwitchContainer from './display/SimpleSwitchContainer'
import styled from 'styled-components'

interface InclusionSwitchProps {
  id?: string
  checked?: boolean
  loading?: boolean
  disabled?: boolean
  onClick?: (value: boolean) => void
}

const InclusionSwitchText = styled.div`
  &.disabled {
    color: var(--colorTextDisabled, rgba(255, 255, 255, 0.25));
  }
`

const InclusionSwitch = ({ id, checked, ...props }: InclusionSwitchProps) => {
  return (
    <SimpleSwitchContainer>
      <SimpleSwitch
        data-testid={`simple-switch-${id}`}
        checked={checked}
        {...props}
      />
      <InclusionSwitchText
        data-testid={`simple-switch-text-${id}`}
        className={props.disabled ? 'disabled' : ''}
      >
        {checked ? t('Included') : t('Excluded')}
      </InclusionSwitchText>
    </SimpleSwitchContainer>
  )
}

export default InclusionSwitch
