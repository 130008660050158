import { Select } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../hooks'
import { RiskReliefConfig } from '../../../store/reducers/workspaceReducer'
import { SelectOption } from '../../../types/generalTypes'

interface SmartFunctionSelectRunProps {
  onChange?: (runId: string | undefined) => void
  fetchData?: boolean
}

const SmartFunctionSelectRun = ({
  onChange,
  fetchData = false,
}: SmartFunctionSelectRunProps) => {
  const { workspaceId, algorithmRunId, deviationId } = useParams()
  const { loading } = useAlgorithmRunDetails({
    workspaceId,
    algorithmRunId,
    preventFetch: !fetchData,
  })
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    algorithmRunId
  )

  const handleChange = (value: string) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  const filteredAlgorithmRuns = useMemo(() => {
    return selectedWorkspace?.algorithmRuns?.filter(
      (run) =>
        (run.config as RiskReliefConfig)?.deviationId === deviationId
    )
  }, [selectedWorkspace?.algorithmRuns, deviationId])


  const options: SelectOption[] = useMemo(
    () =>
      filteredAlgorithmRuns?.map(
        (run, index) =>
          ({
            label: t('smartFunctionDetailsPage.selectRunLabel', {
              count: index + 1,
              total: filteredAlgorithmRuns?.length ?? 0,
            }),
            value: run.id,
          } as SelectOption)
      ) ?? [],
    [filteredAlgorithmRuns]
  )

  useEffect(() => {
    const matchingOption = options.find(
      (option) => option.value === algorithmRunId
    )
    setSelectedValue(matchingOption ? matchingOption.value : undefined)
  }, [algorithmRunId, options])

  return (
    <Select
      onChange={handleChange}
      value={selectedValue}
      options={options}
      placeholder={t('smartFunctionDetailsPage.selectRunLabel', {
        count: filteredAlgorithmRuns?.length ?? 0,
        total: filteredAlgorithmRuns?.length ?? 0,
      })}
      loading={loading}
    />
  )
}

export default SmartFunctionSelectRun
