import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { NO_DATA_PLACEHOLDER } from '../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../hooks'
import { smartFunctionService } from '../../../../services'
import workspaceService from '../../../../services/workspaceService'
import {
  AlgorithmRun,
  QualityReviewRunResult,
} from '../../../../store/reducers/workspaceReducer'
import WorkspaceStatistic from './WorkspaceStatistic'
import WorkspaceStatisticsPanelContainer from './WorkspaceStatisticsPanelContainer'

interface QualityReviewSmartFunctionStatistics {
  totalRuns?: number
  totalDocuments?: number
  totalDeviations?: number
}

const QualityReviewSmartFunctionStatisticsPanel = () => {
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })
  const [statistics, setStatistics] =
    useState<QualityReviewSmartFunctionStatistics>()

  useEffect(() => {
    const mostRecentCompleteAlgorithmRun =
      workspaceService.getMostRecentCompletedAlgorithmRun({
        workspace: selectedWorkspace,
      })

    if (mostRecentCompleteAlgorithmRun && selectedWorkspace?.id) {
      smartFunctionService
        .getWorkspaceAlgorithmRun({
          workspaceId: selectedWorkspace?.id,
          algorithmRunId: mostRecentCompleteAlgorithmRun.id,
        })
        .then((res: AlgorithmRun) => {
          const smartFunctionResult = res.runResult as QualityReviewRunResult
          setStatistics({
            totalRuns: selectedWorkspace?.algorithmRuns?.length,
            totalDocuments: Object.keys(smartFunctionResult).length,
            totalDeviations: Object.values(smartFunctionResult).reduce(
              (acc, curr) =>
                acc +
                curr.reduce(
                  (count, document) =>
                    document.qualityReviewDeviation !== null &&
                    document.qualityReviewDeviation !== undefined
                      ? count + 1
                      : count,
                  0
                ),
              0
            ),
          })
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [selectedWorkspace])

  return (
    <WorkspaceStatisticsPanelContainer>
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.qualityReviewStatistics.smartFunctionRuns'
        )}
        value={statistics?.totalRuns ?? NO_DATA_PLACEHOLDER}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.qualityReviewStatistics.totalDocuments'
        )}
        value={statistics?.totalDocuments ?? NO_DATA_PLACEHOLDER}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.qualityReviewStatistics.totalDeviations'
        )}
        value={statistics?.totalDeviations ?? NO_DATA_PLACEHOLDER}
      />
    </WorkspaceStatisticsPanelContainer>
  )
}

export default QualityReviewSmartFunctionStatisticsPanel
