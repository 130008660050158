import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { riskReliefDeviationStatuses } from '../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../hooks'
import { smartFunctionService } from '../../../../services'
import workspaceService from '../../../../services/workspaceService'
import {
  AlgorithmRun,
  QueryDeviation,
} from '../../../../store/reducers/workspaceReducer'
import WorkspaceStatistic from './WorkspaceStatistic'
import WorkspaceStatisticsPanelContainer from './WorkspaceStatisticsPanelContainer'

interface RiskReliefStatistics {
  numberOfOpenedDeviations?: number
  numberOfClosedDeviationsLastWeek?: number
  numberOfClosedDeviationsLastTwoWeeks?: number
}

const RiskReliefStatisticsPanel = () => {
  const dispatch = useDispatch()
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })
  const [statistics, setStatistics] = useState<RiskReliefStatistics>()

  const getPastDate = (daysAgo: number) => {
    const date = new Date()
    date.setUTCHours(0, 0, 0, 0) // normalize utc
    date.setUTCDate(date.getUTCDate() - daysAgo)
    return date
  }

  const filterDeviationsByStatus = (
    deviations: QueryDeviation[],
    status: string
  ) => deviations.filter((deviation) => deviation.deviationStatus === status)

  const filterDeviationsByDate = (
    deviations: QueryDeviation[],
    fromDate: Date
  ) =>
    deviations.filter((deviation) => {
      if (!deviation.deviationStatusChangedAt) return false

      const deviationDate = new Date(deviation.deviationStatusChangedAt)
      deviationDate.setUTCHours(0, 0, 0, 0) // normalize utc

      return deviationDate >= fromDate
    })

  const calculateStatistics = useCallback(
    (res: AlgorithmRun) => {
      const { config } = selectedWorkspace || {}
      const queryDeviations = config?.riskReliefConfig?.queryDeviations || []

      const openedDeviations = filterDeviationsByStatus(
        queryDeviations,
        riskReliefDeviationStatuses.OPENED
      )
      const closedDeviations = filterDeviationsByStatus(
        queryDeviations,
        riskReliefDeviationStatuses.CLOSED
      )
      const last7Days = getPastDate(7)
      const last14Days = getPastDate(14)

      const closedDeviationsLastWeek = filterDeviationsByDate(
        closedDeviations,
        last7Days
      )
      const closedDeviationsLastTwoWeeks = filterDeviationsByDate(
        closedDeviations,
        last14Days
      )

      const numberOfOpenedDeviations = openedDeviations.length
      const numberOfClosedDeviationsLastWeek = closedDeviationsLastWeek.length
      const numberOfClosedDeviationsLastTwoWeeks =
        closedDeviationsLastTwoWeeks.length

      return {
        numberOfOpenedDeviations,
        numberOfClosedDeviationsLastWeek,
        numberOfClosedDeviationsLastTwoWeeks,
      }
    },
    [selectedWorkspace]
  )

  useEffect(() => {
    const mostRecentCompleteAlgorithmRun =
      workspaceService.getMostRecentCompletedAlgorithmRun({
        workspace: selectedWorkspace,
      })

    if (mostRecentCompleteAlgorithmRun && selectedWorkspace?.id) {
      smartFunctionService
        .getWorkspaceAlgorithmRun({
          workspaceId: selectedWorkspace?.id,
          algorithmRunId: mostRecentCompleteAlgorithmRun.id,
        })
        .then((res) => {
          const values = calculateStatistics(res)
          setStatistics(values)
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [selectedWorkspace, calculateStatistics, dispatch])

  return (
    <WorkspaceStatisticsPanelContainer>
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.riskReleifWorkspaceStatistics.openedDeviations'
        )}
        value={statistics?.numberOfOpenedDeviations || 0}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.riskReleifWorkspaceStatistics.numberOfClosedDeviationsLastWeek'
        )}
        value={statistics?.numberOfClosedDeviationsLastWeek || 0}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.riskReleifWorkspaceStatistics.numberOfClosedDeviationsLastTwoWeeks'
        )}
        value={statistics?.numberOfClosedDeviationsLastTwoWeeks || 0}
      />
    </WorkspaceStatisticsPanelContainer>
  )
}

export default RiskReliefStatisticsPanel
