import { FormInstance } from 'antd'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { useWorkspaces } from '.'
import { riskReliefDeviationStatuses } from '../constants/constants'
import notificationService from '../services/notificationService'
import workspaceService from '../services/workspaceService'
import { Document, TagDetails } from '../store/reducers/searchReviewReducer'

interface UseProcessDeviationProps {
  form?: FormInstance
  onSuccess?: () => void
}

const useProcessDeviation = ({ form, onSuccess }: UseProcessDeviationProps) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { refreshWorkspaceList } = useWorkspaces({ preventFetch: true })

  const addDeviationsToWorkspace = useCallback(
    async (
      searchReviewId: string,
      searchQueryId: string,
      documents: Document[]
    ) => {
      const selectedWorkspace = form?.getFieldValue('workspace')

      if (!selectedWorkspace?.value) {
        notificationService.notificationError(
          t('processDeviationModal.missingData')
        )
        return
      }

      try {
        setConfirmLoading(true)
        const currentWorkspace = await workspaceService.getWorkspaceDetails({
          workspaceId: selectedWorkspace.value,
        })

        const existingConfig = currentWorkspace.config?.riskReliefConfig || {
          searchReviewIds: [],
          queryDeviations: [],
          deviations: [],
        }

        if (!existingConfig.searchReviewIds.includes(searchReviewId)) {
          existingConfig.searchReviewIds.push(searchReviewId)
        }

        existingConfig.queryDeviations = existingConfig.queryDeviations.filter(
          (query) => query.searchQueryId !== searchQueryId
        )

        existingConfig.queryDeviations.push({
          searchReviewId,
          searchQueryId,
          deviationStatus: riskReliefDeviationStatuses.OPENED,
        })

        existingConfig.deviations = existingConfig.deviations.filter(
          (deviation) => deviation.searchQueryId !== searchQueryId
        )

        const newDeviations = documents
          .filter((doc) => doc.summary !== undefined && doc.summary !== null)
          .map((doc) => ({
            searchReviewId,
            searchQueryId,
            searchResultId: doc.id,
            deviationStatus: riskReliefDeviationStatuses.OPENED,
            summary: doc.summary,
            urn: doc.urn,
          }))

        await workspaceService.updateWorkspaceDetails({
          workspaceId: selectedWorkspace.value,
          config: {
            ...currentWorkspace.config,
            riskReliefConfig: {
              searchReviewIds: existingConfig.searchReviewIds,
              queryDeviations: existingConfig.queryDeviations,
              deviations: [...existingConfig.deviations, ...newDeviations],
            },
          },
        })

        notificationService.notificationSuccess(
          t('processDeviationModal.submitSuccess')
        )
        
        await refreshWorkspaceList()
        onSuccess?.()
      } catch (error) {
        console.error('Failed to update workspace:', error)
        notificationService.notificationError(
          t('processDeviationModal.submitError')
        )
      } finally {
        setConfirmLoading(false)
      }
    },
    [form, onSuccess, refreshWorkspaceList]
  )

  const getTop2Tags = (
    tagDetails: TagDetails
  ): { key: string; count: number; score: number }[] => {
    return Object.entries(tagDetails)
      .sort(([, a], [, b]) => b.score - a.score)
      .slice(0, 2)
      .map(([key, value]) => ({ key, ...value }))
  }

  return {
    confirmLoading,
    addDeviationsToWorkspace,
    getTop2Tags,
  }
}

export default useProcessDeviation
