import { LeftOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { ButtonType } from 'antd/lib/button'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

const StyledButton = styled(Button)`
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.04);
`

interface BackButtonProps {
  url: string
  tooltipMessage?: string
  type?: ButtonType
}

const BackButton = ({
  url,
  tooltipMessage,
  type = 'primary',
}: BackButtonProps) => {
  const navigate = useNavigate()
  return (
    <Tooltip
      title={tooltipMessage || t('backButton.tooltipMessage')}
      placement="right"
    >
      <StyledButton
        icon={<LeftOutlined />}
        type={type}
        onClick={() => navigate(url)}
      />
    </Tooltip>
  )
}

export default BackButton
