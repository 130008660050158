import { FieldTimeOutlined, UserOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { t } from 'i18next'
import { useEffect, useMemo } from 'react'
import Moment from 'react-moment'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Badge,
  IconTextContainer,
  MultiColumnFormColumnContainer,
  MultiColumnFormContentContainer,
  SectionLoadingState,
} from '../../../components'
import {
  BadgeColors,
  NO_DATA_PLACEHOLDER,
  dateFormat,
  riskReliefDeviationStatuses,
  workspaceTypes,
} from '../../../constants/constants'
import { useSearchReviews } from '../../../hooks'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'
import RiskReliefDeviationStatisticsPanel from '../components/statistics/RiskReliefDeviationStatisticsPanel'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 124px);
`

interface DeviationDetails {
  historicalReview?: string
  searchQuery?: string
  deviationStatus?: string
  deviationOwner?: string
  deviationLastUpdate?: string
  deviationDataSource?: string
}

const WorkspaceOverviewInfoDeviationPanel = () => {
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })

  const { deviationId } = useParams()

  const {
    refreshSearchReviewList,
    refreshSearchReviewListDone,
    searchReviewList,
    loading,
  } = useSearchReviews({
    preventFetch: true,
  })

  useEffect(() => {
    const searchReviewIds =
      selectedWorkspace?.config?.riskReliefConfig?.searchReviewIds
    if (!!searchReviewIds?.length && !refreshSearchReviewListDone) {
      refreshSearchReviewList(searchReviewIds)
    }
  }, [selectedWorkspace, refreshSearchReviewList, refreshSearchReviewListDone])

  const deviationDetails: DeviationDetails | undefined = useMemo(() => {
    if (!searchReviewList?.length) return undefined

    const allQueries = searchReviewList.flatMap((r) => r.searchQueries)
    if (!allQueries.length) return undefined

    const searchQuery = allQueries.find((q) => q?.id === deviationId)
    if (!searchQuery) return undefined

    const parentReview = searchReviewList.find(
      (r) => r.id === searchQuery.literatureReview
    )

    if (!parentReview) return undefined

    const config =
      selectedWorkspace?.config?.riskReliefConfig?.queryDeviations?.find(
        (c) => c.searchQueryId === deviationId
      )

    return {
      historicalReview: parentReview.title,
      searchQuery: searchQuery.searchText,
      deviationStatus: config?.deviationStatus || '',
      deviationOwner: searchQuery.searchActionBy.displayName,
      deviationLastUpdate: searchQuery.searchDate,
      deviationDataSource: searchQuery.searchSource.title,
    }
  }, [searchReviewList, selectedWorkspace, deviationId])

  const statisticsContent = useMemo(() => {
    let statistic = null
    switch (selectedWorkspace?.typeCode) {
      default:
        statistic = <RiskReliefDeviationStatisticsPanel />
    }
    return statistic
  }, [selectedWorkspace])

  const canShowStatistics = useMemo(() => {
    return selectedWorkspace?.typeCode === workspaceTypes.RISK_REL
  }, [selectedWorkspace])

  if (loading) {
    return (
      <SectionLoadingStateContainer>
        <SectionLoadingState />
      </SectionLoadingStateContainer>
    )
  }

  return (
    <Form layout="vertical" requiredMark={false}>
      <MultiColumnFormContentContainer>
        <MultiColumnFormColumnContainer>
          <Form.Item label={t('Search Query')}>
            {deviationDetails?.searchQuery || NO_DATA_PLACEHOLDER}
          </Form.Item>
          <Form.Item label={t('Historical Review')}>
            {deviationDetails?.historicalReview || NO_DATA_PLACEHOLDER}
          </Form.Item>
          <Form.Item label={t('Data Source')}>
            <Badge color={BadgeColors.DATA_SOURCE}>
              {deviationDetails?.deviationDataSource || NO_DATA_PLACEHOLDER}
            </Badge>
          </Form.Item>
          <Form.Item label={t('Status')}>
            <Badge
              color={
                deviationDetails?.deviationStatus ===
                riskReliefDeviationStatuses.OPENED
                  ? BadgeColors.STATUS_OPENED
                  : BadgeColors.STATUS_CLOSED
              }
            >
              {t(
                `workspaceDetailsPage.deviationStatus.${deviationDetails?.deviationStatus}`
              )}
            </Badge>
          </Form.Item>

          <Form.Item label={t('Last Update')}>
            <IconTextContainer>
              <FieldTimeOutlined />
              {deviationDetails?.deviationLastUpdate ? (
                <Moment
                  date={deviationDetails?.deviationLastUpdate}
                  format={dateFormat.PRIMARY}
                />
              ) : (
                NO_DATA_PLACEHOLDER
              )}
            </IconTextContainer>
          </Form.Item>
          <Form.Item label={t('Owner')}>
            <IconTextContainer>
              <UserOutlined />
              {deviationDetails?.deviationOwner || NO_DATA_PLACEHOLDER}
            </IconTextContainer>
          </Form.Item>
        </MultiColumnFormColumnContainer>
        {canShowStatistics && (
          <MultiColumnFormColumnContainer>
            <Form.Item
              label={t(
                'workspaceDetailsPage.workspaceOverviewInfoPanel.secondDeviationColumnTitle'
              )}
            >
              {selectedWorkspace?.algorithmRuns === undefined ||
              selectedWorkspace?.algorithmRuns?.length === 0 ? (
                <Paragraph
                  style={{
                    paddingLeft: '8px',
                    fontWeight: 400,
                    color: '#434343',
                  }}
                >
                  {t(
                    'workspaceDetailsPage.workspaceOverviewInfoPanel.secondColumnDescription'
                  )}
                </Paragraph>
              ) : (
                statisticsContent
              )}
            </Form.Item>
          </MultiColumnFormColumnContainer>
        )}
      </MultiColumnFormContentContainer>
    </Form>
  )
}

export default WorkspaceOverviewInfoDeviationPanel
