import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { NO_DATA_PLACEHOLDER } from '../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../hooks'
import { smartFunctionService } from '../../../../services'
import workspaceService from '../../../../services/workspaceService'
import {
  AlgorithmRun,
  RegulatorySubmissionRunResult,
} from '../../../../store/reducers/workspaceReducer'
import WorkspaceStatistic from './WorkspaceStatistic'
import WorkspaceStatisticsPanelContainer from './WorkspaceStatisticsPanelContainer'

interface RegulatorySubmissionSmartFunctionStatistics {
  missingExpectedFiles?: number
}

const RegulatorySubmissionSmartFunctionStatisticsPanel = () => {
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })
  const [statistics, setStatistics] =
    useState<RegulatorySubmissionSmartFunctionStatistics>()

  useEffect(() => {
    const mostRecentCompleteAlgorithmRun =
      workspaceService.getMostRecentCompletedAlgorithmRun({
        workspace: selectedWorkspace,
      })

    if (mostRecentCompleteAlgorithmRun && selectedWorkspace?.id) {
      smartFunctionService
        .getWorkspaceAlgorithmRun({
          workspaceId: selectedWorkspace?.id,
          algorithmRunId: mostRecentCompleteAlgorithmRun.id,
        })
        .then((res: AlgorithmRun) => {
          const smartFunctionResult =
            res.runResult as RegulatorySubmissionRunResult
          setStatistics({
            missingExpectedFiles: smartFunctionResult.absent.length,
          })
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [selectedWorkspace])

  return (
    <WorkspaceStatisticsPanelContainer>
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.regulatorySubmissionStatistics.smartFunctionRuns'
        )}
        value={selectedWorkspace?.algorithmRuns?.length ?? NO_DATA_PLACEHOLDER}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.regulatorySubmissionStatistics.missingExpectedFiles'
        )}
        value={statistics?.missingExpectedFiles ?? NO_DATA_PLACEHOLDER}
      />
    </WorkspaceStatisticsPanelContainer>
  )
}

export default RegulatorySubmissionSmartFunctionStatisticsPanel
