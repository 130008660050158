import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NO_DATA_PLACEHOLDER } from '../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../hooks'
import { smartFunctionService } from '../../../../services'
import workspaceService from '../../../../services/workspaceService'
import {
  AlgorithmRun,
  BiomarkersRunResult,
} from '../../../../store/reducers/workspaceReducer'
import WorkspaceStatistic from './WorkspaceStatistic'
import WorkspaceStatisticsPanelContainer from './WorkspaceStatisticsPanelContainer'

interface BioMarkerSmartFunctionStatistics {
  retainedFeatures?: number
  score?: number
}

const BioMarkerSmartFunctionStatisticsPanel = () => {
  const dispatch = useDispatch()
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })

  const [statistics, setStatistics] =
    useState<BioMarkerSmartFunctionStatistics>()

  const calculateStatistics = useCallback((res: AlgorithmRun) => {
    if (!res.runResult) {
      return { retainedFeatures: undefined, score: undefined }
    }

    const biomarkerResult = res.runResult as BiomarkersRunResult
    const bestMask = biomarkerResult.bestMask || [0]

    const retainedFeatures =
      bestMask.reduce((a, b) => a + b, 0) / bestMask.length
    const score = biomarkerResult?.f1Score || 0

    return { retainedFeatures, score }
  }, [])

  useEffect(() => {
    const mostRecentCompleteAlgorithmRun =
      workspaceService.getMostRecentCompletedAlgorithmRun({
        workspace: selectedWorkspace,
      })

    if (mostRecentCompleteAlgorithmRun && selectedWorkspace?.id) {
      smartFunctionService
        .getWorkspaceAlgorithmRun({
          workspaceId: selectedWorkspace?.id,
          algorithmRunId: mostRecentCompleteAlgorithmRun.id,
        })
        .then((res) => {
          const values = calculateStatistics(res)
          setStatistics(values)
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [selectedWorkspace, calculateStatistics, dispatch])

  return (
    <WorkspaceStatisticsPanelContainer>
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.biomarkerStatistics.retainedFeatures'
        )}
        value={
          statistics?.retainedFeatures
            ? statistics.retainedFeatures.toFixed(2)
            : NO_DATA_PLACEHOLDER
        }
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.biomarkerStatistics.score'
        )}
        value={
          statistics?.score ? statistics.score.toFixed(2) : NO_DATA_PLACEHOLDER
        }
      />
    </WorkspaceStatisticsPanelContainer>
  )
}

export default BioMarkerSmartFunctionStatisticsPanel
