import { FileTextOutlined } from '@ant-design/icons'
import { Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Badge,
  SectionLoadingState,
  SmartFunctionRunStatusIcon,
  TableBoldColumnContainer,
} from '../../../components'
import {
  BACK_END_PULL_INTERVAL,
  NO_DATA_PLACEHOLDER,
  RiskReliefSmartFunctionType,
  algorithmRunStatuses,
  dateFormat,
  riskReliefDeviationStatuses,
  riskReliefSmartFunctionTypes,
  smartFunctionBadgeStatusColorKeys,
  workspaceTypes,
} from '../../../constants/constants'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'
import useWorkspaces from '../../../hooks/useWorkspaces'
import {
  AlgorithmRun,
  RiskReliefAlgorithmRun,
  RiskReliefConfig,
  QualityReviewConfigValues,
  clearSelectedAlgorithmRun,
} from '../../../store/reducers/workspaceReducer'
import { AlgorithmRunStatus } from '../../../types/generalTypes'
import { routePaths } from '../../RootPage'
import SmartFunctionActionDropdown from '../../SmartFunctions/components/SmartFunctionActionDropdown/SmartFunctionActionDropdown'

const ListPanelLayout = styled.div`
  height: calc(100vh - 124px);
  border-radius: 16px 16px 0px 0px;
`

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 124px);
`

interface ExtendedAlgorithmRun extends AlgorithmRun {
  key: string
  smartFunctionName?: string
  algorithmRunIndex?: number
  statusText?: string
  isStatusOngoing?: boolean
  smartFunctionType?: RiskReliefSmartFunctionType
}

interface WorkspaceSmartFunctionPanelProps {
  singleWorkspace?: boolean
}

const WorkspaceSmartFunctionPanel = ({
  singleWorkspace,
}: WorkspaceSmartFunctionPanelProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { workspaceId, deviationId } = useParams()
  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const { loading } = useWorkspaces({ workspaceId, preventFetch: true })
  const isStatusOngoing = useCallback(
    (runs: AlgorithmRun[]) =>
      !!runs?.find(
        (run) =>
          run.runStatus === algorithmRunStatuses.ONGOING_INGESTION ||
          run.runStatus === algorithmRunStatuses.ONGOING_AI
      ),
    []
  )

  const getStatusText = useCallback(
    (status: AlgorithmRunStatus | undefined) =>
      status
        ? t(`workspaceDetailsPage.status.${status}`)
        : t(`workspaceDetailsPage.status.${algorithmRunStatuses.READY}`) ||
          NO_DATA_PLACEHOLDER,
    []
  )

  const algorithmRunBase: ExtendedAlgorithmRun = useMemo(
    () => ({
      id: '0',
      key: '0',
      workspace: selectedWorkspace?.title || '',
      dataSource:
        typeof selectedWorkspace?.dataSource === 'string'
          ? selectedWorkspace?.dataSource
          : selectedWorkspace?.dataSource?.id,
      runStatus: algorithmRunStatuses.READY,
      statusText: t(
        `workspaceDetailsPage.status.${algorithmRunStatuses.READY}`
      ),
      smartFunctionName: selectedWorkspace?.algorithmName || '',
    }),
    [selectedWorkspace]
  )

  const getRecord = useCallback(
    (record: ExtendedAlgorithmRun) => {
      if (selectedWorkspace?.typeCode !== workspaceTypes.RISK_REL) {
        return record
      }
      const runs = selectedWorkspace.algorithmRuns?.filter((run) => {
        const config: RiskReliefConfig = run?.config as RiskReliefConfig
        return !!config?.deviations && config.deviationId === deviationId
      })
      const latestRun = runs ? runs[runs.length - 1] : undefined
      if (!latestRun) {
        return record
      }
      return record.smartFunctionType ===
        riskReliefSmartFunctionTypes.CAPA_GENERATION
        ? ({
            ...latestRun,
            smartFunctionType: record.smartFunctionType,
          } as RiskReliefAlgorithmRun)
        : latestRun
    },
    [selectedWorkspace, deviationId]
  )

  const getRecordId = useCallback(
    (recordId: string) =>
      selectedWorkspace?.typeCode === workspaceTypes.RISK_REL
        ? selectedWorkspace.algorithmRuns && selectedWorkspace.algorithmRuns[0]
          ? (() => {
              const runs = selectedWorkspace.algorithmRuns.filter((run) => {
                const config: RiskReliefConfig = run?.config as RiskReliefConfig
                return (
                  !!config?.deviations && config.deviationId === deviationId
                )
              })
              return runs.length > 0 ? runs[runs.length - 1]?.id : '0'
            })()
          : '0'
        : recordId,
    [selectedWorkspace, deviationId]
  )

  const riskReliefSmartFunctions: ExtendedAlgorithmRun[] = useMemo(() => {
    const runs = selectedWorkspace?.algorithmRuns?.filter((run) => {
      const config: RiskReliefConfig = run?.config as RiskReliefConfig
      return !!config?.deviations && config.deviationId === deviationId
    })

    const latestRun = runs ? runs[runs.length - 1] : undefined
    return [
      {
        ...algorithmRunBase,
        id: '1',
        key: '1',
        smartFunctionName: t(
          'workspaceDetailsPage.riskReliefSmartFunction.deviationClassification'
        ),
        smartFunctionType:
          riskReliefSmartFunctionTypes.DEVIATION_CLASSIFICATION,
        algorithmRunIndex: runs ? runs.length : 0,
        runStatus: latestRun?.nodeStatuses?.DEVIATION_CLASSIFICATION,
        statusText: getStatusText(
          latestRun?.nodeStatuses?.DEVIATION_CLASSIFICATION
        ),
        isStatusOngoing: runs ? isStatusOngoing(runs) : false,
        actionDate: latestRun?.actionDate,
      },
      {
        ...algorithmRunBase,
        id: '2',
        key: '2',
        smartFunctionName: t(
          'workspaceDetailsPage.riskReliefSmartFunction.rootCauseDiscovery'
        ),
        smartFunctionType: riskReliefSmartFunctionTypes.ROOT_CAUSE_DISCOVERY,
        algorithmRunIndex: runs ? runs.length : 0,
        runStatus: latestRun?.nodeStatuses?.ROOT_CAUSE_DISCOVERY,
        statusText: getStatusText(
          latestRun?.nodeStatuses?.ROOT_CAUSE_DISCOVERY
        ),
        isStatusOngoing: runs ? isStatusOngoing(runs) : false,
        actionDate: latestRun?.actionDate,
      },
      {
        ...algorithmRunBase,
        id: '3',
        key: '3',
        smartFunctionName: t(
          'workspaceDetailsPage.riskReliefSmartFunction.capaGeneration'
        ),
        smartFunctionType: riskReliefSmartFunctionTypes.CAPA_GENERATION,
        algorithmRunIndex: runs ? runs.length : 0,
        runStatus: latestRun?.nodeStatuses?.CAPA_GENERATION,
        statusText: getStatusText(latestRun?.nodeStatuses?.CAPA_GENERATION),
        isStatusOngoing: runs ? isStatusOngoing(runs) : false,
        actionDate: latestRun?.actionDate,
      },
    ]
  }, [
    algorithmRunBase,
    deviationId,
    getStatusText,
    isStatusOngoing,
    selectedWorkspace,
  ])

  const formattedData: ExtendedAlgorithmRun[] = useMemo(() => {
    const defaultRun: ExtendedAlgorithmRun = algorithmRunBase
    if (selectedWorkspace?.typeCode === workspaceTypes.RISK_REL) {
      return riskReliefSmartFunctions
    } else {
      const runs = selectedWorkspace?.algorithmRuns
      return !!runs && runs.length > 0
        ? runs.map((run: AlgorithmRun, index: number) => ({
            ...run,
            key: run.id,
            smartFunctionName: selectedWorkspace?.algorithmName ?? '',
            algorithmRunIndex: index + 1,
            statusText: run?.runStatus
              ? t(`workspaceDetailsPage.status.${run?.runStatus}`) ||
                NO_DATA_PLACEHOLDER
              : NO_DATA_PLACEHOLDER,
            isStatusOngoing: selectedWorkspace?.algorithmRuns
              ? isStatusOngoing(selectedWorkspace?.algorithmRuns)
              : false,
          }))
        : [defaultRun]
    }
  }, [
    selectedWorkspace,
    isStatusOngoing,
    algorithmRunBase,
    riskReliefSmartFunctions,
  ])

  useEffect(() => {
    const interval = setInterval(() => {
      refreshWorkspaceDetails()
    }, BACK_END_PULL_INTERVAL)

    return () => clearInterval(interval) // Clear the interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // checking if the workspace is A RISK REL workspace with deviation that is closed. If So we will disable the smart function action dropdown
  const isRiskReleiftWorkspaceDeviationClosed = useMemo(() => {
    return (
      (selectedWorkspace?.typeCode === workspaceTypes.RISK_REL &&
        selectedWorkspace?.config?.riskReliefConfig?.queryDeviations.some(
          (deviation) =>
            deviation.searchQueryId === deviationId &&
            deviation.deviationStatus === riskReliefDeviationStatuses.CLOSED
        )) ||
      false
    )
  }, [selectedWorkspace, deviationId])

  const columns: ColumnsType<ExtendedAlgorithmRun> = [
    {
      title: t('Name'),
      dataIndex: 'smartFunctionName',
      key: 'smartFunctionName',
      width: '40%',
      render: (text, record) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Date'),
      dataIndex: 'actionDate',
      key: 'actionDate',
      width: '15%',
      render: (actionDate) => (
        <>
          {actionDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {actionDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: t('workspaceDetailsPage.workspaceSmartFunctionPanel.runNumber'),
      key: 'runCount',
      render: (text, record) => (
        <>
          {t('smartFunctionDetailsPage.runCount', {
            count: record.algorithmRunIndex || 0,
          })}
        </>
      ),
    },
    {
      title: t('Status'),
      dataIndex: 'statusText',
      key: 'statusText',
      render: (text: string, record: ExtendedAlgorithmRun) => (
        <Badge
          color={
            smartFunctionBadgeStatusColorKeys[
              record.runStatus as AlgorithmRunStatus
            ]
          }
          icon={
            <SmartFunctionRunStatusIcon
              status={record.runStatus as AlgorithmRunStatus}
            />
          }
        >
          {text || NO_DATA_PLACEHOLDER}
        </Badge>
      ),
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (text, record) => {
        return (
          <>
            <SmartFunctionActionDropdown
              key={getRecordId(record.id)}
              algorithmRun={getRecord(record)}
              disabled={isRiskReleiftWorkspaceDeviationClosed}
            />
          </>
        )
      },
    },
  ]

  if (selectedWorkspace?.typeCode === workspaceTypes.OFR_QR) {
    columns.splice(1, 0, {
      title: t('workspaceDetailsPage.workspaceSmartFunctionPanel.template'),
      dataIndex: 'config',
      key: 'config',
      width: '15%',
      render: (config: QualityReviewConfigValues) => {
        const fileName = config?.documentTemplateName
        return (
          <>
            {fileName ? (
              <Tag
                icon={<FileTextOutlined />}
                color="default"
                style={{
                  textOverflow: 'ellipsis',
                }}
              >
                {fileName}
              </Tag>
            ) : (
              NO_DATA_PLACEHOLDER
            )}
          </>
        )
      },
    })
  }

  const handleRecordClick = (record: ExtendedAlgorithmRun) => {
    if (record.id !== '0') {
      dispatch(clearSelectedAlgorithmRun())
      let baseRoute = ''
      let extra = ''
      switch (selectedWorkspace?.typeCode) {
        case workspaceTypes.BIOMARK:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK
          break
        case workspaceTypes.REFAI:
          baseRoute = singleWorkspace
            ? routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
            : routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
          break
        case workspaceTypes.REGSUB:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB
          break
        case workspaceTypes.DEL:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL
          break
        case workspaceTypes.OFR_QR:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR
          break
        case workspaceTypes.RISK_REL:
          switch (record.id) {
            case '1':
              baseRoute =
                routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION
              extra = `/${deviationId}`
              break
            case '2':
              baseRoute =
                routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_ROOT_CAUSE_DISCOVERY
              extra = `/${deviationId}`
              break
            case '3':
              baseRoute =
                routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_CAPA_GENERATION
              extra = `/${deviationId}`
              break
          }
      }

      navigate(
        `${baseRoute}/${selectedWorkspace?.id}/${getRecordId(
          record.id
        )}${extra}`
      )
    }
  }

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <ListPanelLayout>
      <Table
        rowClassName={(record) =>
          record.id !== '0' &&
          record.runStatus === algorithmRunStatuses.COMPLETE
            ? 'page-list-table-row clickable'
            : 'page-list-table-row'
        }
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleRecordClick(record)
            },
          }
        }}
        scroll={{ y: 'calc(100vh - 155px)' }}
      />
    </ListPanelLayout>
  )
}

export default WorkspaceSmartFunctionPanel
