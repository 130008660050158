import { t } from 'i18next'
import { useMemo } from 'react'
import { styled } from 'styled-components'
import {
  NO_DATA_PLACEHOLDER,
  RiskReliefDeviationRisk,
  RiskReliefDeviationSeverity,
} from '../../../constants/constants'

// Styled Components
const CellContainer = styled.div<{ $cellType?: CellType }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
  border: solid 1px
    ${({ $cellType, theme }) =>
      $cellType === 'risk' || $cellType === 'severity'
        ? theme.token.colorBorder
        : theme.token.colorSplit};
  background-color: ${({ $cellType, theme }) =>
    $cellType === 'risk' || $cellType === 'severity'
      ? theme.token.colorBgContainer
      : theme.token.colorFillAlter};
  width: 100%;
  height: 90px;
  gap: 4px;
`

const FirstLine = styled.div`
  opacity: 0.45;
  min-width: 100%;
  font-size: 14px;
  font-weight: 400;
`

const SecondLine = styled.div`
  font-size: 24px;
  font-weight: 600;
`

type CellType = 'basic' | 'risk' | 'deviation' | 'severity'

interface RiskReliefCellProps {
  title: string
  deviationValue?: RiskReliefDeviationSeverity
  risk?: RiskReliefDeviationRisk
  cellType?: CellType
  text?: string
}

// Component
const RiskReliefCell = ({
  title,
  deviationValue,
  risk,
  cellType,
  text,
}: RiskReliefCellProps) => {
  const secondLineText = useMemo(() => {
    if (cellType === 'severity' || cellType === 'deviation') {
      return deviationValue
        ? t(
            `smartFunctionDetailsPage.riskReliefClassificationDeviation.deviationValues.${deviationValue}`
          )
        : NO_DATA_PLACEHOLDER
    }

    if (cellType === 'risk') {
      return risk
        ? t(
            `smartFunctionDetailsPage.riskReliefClassificationDeviation.deviationRiskValues.${risk}`
          )
        : NO_DATA_PLACEHOLDER
    }

    return text || NO_DATA_PLACEHOLDER
  }, [cellType, deviationValue, risk, text])

  return (
    <CellContainer $cellType={cellType}>
      <FirstLine>{title}</FirstLine>
      <SecondLine>{secondLineText}</SecondLine>
    </CellContainer>
  )
}

export default RiskReliefCell
