import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

interface RootCauseInclusion {
  [rootCauseTag: string]: string[]
}

interface RootCauseInclusionsState {
  [key: string]: RootCauseInclusion
}

const makeKey = (workspaceId: string, algorithmRunId: string) =>
  `${workspaceId}-${algorithmRunId}`

interface SetRootCauseInclusionsPayload {
  workspaceId: string
  algorithmRunId: string
  inclusions: RootCauseInclusion
}

interface ToggleDocumentInclusionPayload {
  workspaceId: string
  algorithmRunId: string
  rootCauseTag: string
  documentUrn: string
}

interface ToggleAllDocumentsOfRootCausePayload {
  workspaceId: string
  algorithmRunId: string
  rootCauseTag: string
  documentUrns: string[]
}

interface ClearRootCauseInclusionsPayload {
  workspaceId?: string
  algorithmRunId?: string
}

const initialState: RootCauseInclusionsState = {}

const rootCauseInclusionsSlice = createSlice({
  name: 'rootCauseInclusions',
  initialState,
  reducers: {
    setRootCauseInclusions: (
      state,
      action: PayloadAction<SetRootCauseInclusionsPayload>
    ) => {
      const key = makeKey(
        action.payload.workspaceId,
        action.payload.algorithmRunId
      )
      state[key] = action.payload.inclusions
    },

    toggleDocumentInclusion: (
      state,
      action: PayloadAction<ToggleDocumentInclusionPayload>
    ) => {
      const { workspaceId, algorithmRunId, rootCauseTag, documentUrn } =
        action.payload
      const key = makeKey(workspaceId, algorithmRunId)

      if (!state[key]) {
        state[key] = {}
      }

      const current = state[key][rootCauseTag] || []
      const exists = current.includes(documentUrn)

      if (exists) {
        const updated = current.filter((urn) => urn !== documentUrn)
        if (updated.length > 0) {
          state[key][rootCauseTag] = updated
        } else {
          delete state[key][rootCauseTag]
        }
      } else {
        state[key][rootCauseTag] = [...current, documentUrn]
      }

      if (Object.keys(state[key]).length === 0) {
        delete state[key]
      }
    },

    toggleAllDocumentsOfRootCause: (
      state,
      action: PayloadAction<ToggleAllDocumentsOfRootCausePayload>
    ) => {
      const { workspaceId, algorithmRunId, rootCauseTag, documentUrns } =
        action.payload
      const key = makeKey(workspaceId, algorithmRunId)

      if (!state[key]) {
        state[key] = {}
      }

      const current = state[key][rootCauseTag] || []
      const allSelected = documentUrns.every((urn) => current.includes(urn))

      if (allSelected) {
        delete state[key][rootCauseTag]
      } else {
        state[key][rootCauseTag] = documentUrns
      }

      if (Object.keys(state[key]).length === 0) {
        delete state[key]
      }
    },

    clearRootCauseInclusions: (
      state,
      action: PayloadAction<ClearRootCauseInclusionsPayload>
    ) => {
      const payload = action.payload

      if (!payload) {
        for (const key in state) {
          delete state[key]
        }
      } else {
        const { workspaceId, algorithmRunId } = payload

        for (const key of Object.keys(state)) {
          const [w, a] = key.split('-') // separate workspaceId and algorithmRunId from the key
          if (
            (!workspaceId || workspaceId === w) &&
            (!algorithmRunId || algorithmRunId === a)
          ) {
            delete state[key]
          }
        }
      }
    },
  },
})

export const {
  setRootCauseInclusions,
  toggleDocumentInclusion,
  toggleAllDocumentsOfRootCause,
  clearRootCauseInclusions,
} = rootCauseInclusionsSlice.actions

export const selectRootCauseInclusions = (
  state: RootState,
  workspaceId: string,
  algorithmRunId: string
): RootCauseInclusion => {
  const key = makeKey(workspaceId, algorithmRunId)
  return state.rootCauseInclusions[key] || {}
}

export default rootCauseInclusionsSlice.reducer
