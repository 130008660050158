import { t } from 'i18next'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import notificationService from '../services/notificationService'
import { RootState } from '../store'
import {
  selectRootCauseInclusions,
  toggleAllDocumentsOfRootCause,
  toggleDocumentInclusion,
} from '../store/reducers/rootCauseInclusionReducer'
import { RiskReliefRootCauseDetails } from '../store/reducers/workspaceReducer'

export interface UseRootCauseInclusionsProps {
  workspaceId: string
  algorithmRunId: string
}

export interface RiskReliefRootCauseDetailsExtended
  extends RiskReliefRootCauseDetails {
  included: boolean
}

export const useRootCauseInclusions = ({
  workspaceId,
  algorithmRunId,
}: UseRootCauseInclusionsProps) => {
  const dispatch = useDispatch()
  const inclusions = useSelector((state: RootState) =>
    selectRootCauseInclusions(state, workspaceId, algorithmRunId)
  )

  interface MasterOnSwitchClickProps {
    recordDetails: RiskReliefRootCauseDetailsExtended[]
    rootCauseTag: string
  }

  const handleMasterOnSwitchClick = useCallback(
    ({ recordDetails, rootCauseTag }: MasterOnSwitchClickProps) => {
      const documentUrns = recordDetails.map((detail) => detail.urn)
      dispatch(
        toggleAllDocumentsOfRootCause({
          workspaceId,
          algorithmRunId,
          rootCauseTag,
          documentUrns,
        })
      )

      const currentIncludedIds = inclusions[rootCauseTag] || []
      const hasUnexcludedDocuments = documentUrns.some(
        (id) => currentIncludedIds.includes(id)
      )

      notificationService.notificationSuccess(
        t(
          `smartFunctionDetailsPage.riskReliefRootCause.${
            hasUnexcludedDocuments ? 'excludeAllSuccess' : 'includeAllSuccess'
          }`,
          {
            tag: rootCauseTag,
          }
        )
      )
    },
    [dispatch, inclusions, workspaceId, algorithmRunId]
  )

  interface OnSwitchClickProps {
    documentId: string
    urn: string
    rootCauseTag: string
  }

  const handleOnSwitchClick = useCallback(
    ({ documentId, urn, rootCauseTag }: OnSwitchClickProps) => {
      dispatch(
        toggleDocumentInclusion({
          workspaceId,
          algorithmRunId,
          rootCauseTag,
          documentUrn: urn,
        })
      )

      const currentIncludedIds = inclusions[rootCauseTag] || []
      const isCurrentlyIncluded = currentIncludedIds.includes(urn)

      notificationService.notificationSuccess(
        t(
          `smartFunctionDetailsPage.riskReliefRootCause.${
            isCurrentlyIncluded ? 'excludeSuccess' : 'includeSuccess'
          }`,
          {
            tag: rootCauseTag,
            id: documentId,
          }
        )
      )
    },
    [dispatch, inclusions, workspaceId, algorithmRunId]
  )

  interface IsDocumentIncludedProps {
    urn: string
    rootCauseTag: string
  }

  const isDocumentIncluded = useCallback(
    ({ urn, rootCauseTag }: IsDocumentIncludedProps) => {
      return (inclusions[rootCauseTag] || []).includes(urn)
    },
    [inclusions]
  )

  return {
    handleMasterOnSwitchClick,
    handleOnSwitchClick,
    isDocumentIncluded,
    inclusions,
  }
}

export default useRootCauseInclusions
