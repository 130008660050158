import { BugOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { t } from 'i18next'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  Badge,
  ExpandableTable,
  InclusionSwitch,
  SectionLoadingState,
  TableBoldColumnContainer,
} from '../../../../components'
import {
  algorithmRunStatuses,
  BadgeColors,
} from '../../../../constants/constants'
import {
  useAlgorithmRunDetails,
  useRootCauseInclusions,
  useWorkspaceDetails,
} from '../../../../hooks'
import { RiskReliefRootCauseDetailsExtended } from '../../../../hooks/useRootCauseInclusions'
import { setRootCauseInclusions } from '../../../../store/reducers/rootCauseInclusionReducer'
import { RiskReliefConfig } from '../../../../store/reducers/workspaceReducer'
import { SmartFunctionListPanelLayout } from '../../components/SmartFunctionListPanelLayout'

const MainLabelContainer = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
`

const BadgeContainer = styled.div`
  margin-left: 8px;
`

const ExpandedSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--color-bg-base);
  min-height: 48px;
  padding: 13px 4px;
  align-items: flex-start;
  flex: 1 0 0;
`

const ExpandedSectionRow = styled.div`
  display: flex;
  padding: 8px 0px;
  width: 100%;
`

const ExpandedSectionItemContainer = styled.div`
  padding: 0px 8px;
`

const PaddingBlock = styled.div`
  min-width: 48px;
  height: 100%;
`

const IdDetailsContainer = styled(ExpandedSectionItemContainer)`
  font-weight: 600;
  width: 40%;
`

const SummaryContainer = styled(ExpandedSectionItemContainer)`
  width: 40%;
`

const ToggleMainContainer = styled(ExpandedSectionItemContainer)`
  min-width: 20%;
  margin-left: -10px;
`

const ToggleDetailsContainer = styled(ExpandedSectionItemContainer)`
  width: 20%;
`

const SummarySubtitle = styled.div`
  color: ${(props) => props.theme.token.colorTextSecondary};
`

interface RootCauseMainRow {
  tag: string
  key: string
  count: number
  total: number
  toggle: boolean
  details: RiskReliefRootCauseDetailsExtended[]
}

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

const RiskReliefRootCauseDiscoverySmartFunctionDetailsPagePanel = () => {
  const { workspaceId, algorithmRunId, deviationId } = useParams()
  const dispatch = useDispatch()
  const { selectedAlgorithmRun, loading } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
  })

  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  useEffect(() => {
    const config = selectedAlgorithmRun?.config as RiskReliefConfig | undefined
    const isCapaGenerationPaused =
      selectedAlgorithmRun?.nodeStatuses?.CAPA_GENERATION ===
      algorithmRunStatuses.PAUSED

    if (!isCapaGenerationPaused && config?.rootCauseInclusions) {
      dispatch(
        setRootCauseInclusions({
          workspaceId: workspaceId || '',
          algorithmRunId: algorithmRunId || '',
          inclusions: config.rootCauseInclusions,
        })
      )
    } else if (isCapaGenerationPaused && !config?.rootCauseInclusions) {
      dispatch(
        setRootCauseInclusions({
          workspaceId: workspaceId || '',
          algorithmRunId: algorithmRunId || '',
          inclusions: {},
        })
      )
    }
  }, [selectedAlgorithmRun, workspaceId, algorithmRunId, dispatch])

  const rootCauseDiscovery = useMemo(
    () =>
      selectedAlgorithmRun?.nodeResults
        ? selectedAlgorithmRun?.nodeResults?.ROOT_CAUSE_DISCOVERY
        : undefined,
    [selectedAlgorithmRun]
  )

  const totalCauses = useMemo(() => {
    const values = Object.values(rootCauseDiscovery ?? {})
    return values.reduce((sum, value) => sum + (value.details?.length ?? 0), 0)
  }, [rootCauseDiscovery])

  const formattedData = useMemo(() => {
    let rootCauses: RootCauseMainRow[] = []
    for (const [key, value] of Object.entries(rootCauseDiscovery ?? {})) {
      rootCauses.push({
        tag: key,
        key,
        count: value.details?.length ?? 0,
        total: totalCauses,
        toggle: true,
        details: [
          ...(value.details
            ? value.details.map((detail) => ({
                ...detail,
                included: true,
              }))
            : []),
        ],
      } as RootCauseMainRow)
    }

    return rootCauses.sort((a, b) => b.count - a.count)
  }, [rootCauseDiscovery, totalCauses])

  const { handleMasterOnSwitchClick, handleOnSwitchClick, isDocumentIncluded } =
    useRootCauseInclusions({
      workspaceId: workspaceId || '',
      algorithmRunId: algorithmRunId || '',
    })

  const disableInclusionSwitches = useMemo(() => {
    const config = selectedAlgorithmRun?.config as RiskReliefConfig | undefined

    const latestSf = selectedWorkspace?.algorithmRuns?.filter(
      (algorithmRun) =>
        (algorithmRun.config as RiskReliefConfig)?.deviationId === deviationId
    )

    const latestSfRunId = latestSf?.[latestSf.length - 1]?.id

    const isLatestSfRun = latestSfRunId === algorithmRunId
    const isCapaPaused =
      selectedAlgorithmRun?.nodeStatuses?.CAPA_GENERATION ===
      algorithmRunStatuses.PAUSED
    const hasRootCauseInclusions = !!config?.rootCauseInclusions
    return !(isLatestSfRun && isCapaPaused && !hasRootCauseInclusions)
  }, [selectedAlgorithmRun, selectedWorkspace, algorithmRunId, deviationId])

  const handleRootCauseInclusionSwitchClick = (record: RootCauseMainRow) => {
    handleMasterOnSwitchClick({
      recordDetails: record.details,
      rootCauseTag: record.tag,
    })
  }

  const handleDocumentSwitchClick = (
    record: RootCauseMainRow,
    row: RiskReliefRootCauseDetailsExtended
  ) => {
    handleOnSwitchClick({
      documentId: row.documentId,
      urn: row.urn,
      rootCauseTag: record.tag,
    })
  }

  const checkForExclusion = (
    record: RootCauseMainRow,
    row: RiskReliefRootCauseDetailsExtended
  ) => {
    return isDocumentIncluded({
      urn: row.urn,
      rootCauseTag: record.tag,
    })
  }

  const expandedRowRender = (record: RootCauseMainRow) => (
    <ExpandedSection key={record.key}>
      {record.details &&
        record.details.map((row: RiskReliefRootCauseDetailsExtended) => (
          <ExpandedSectionRow key={`${row.documentId}-${record.tag}`}>
            <PaddingBlock />
            <IdDetailsContainer>{row.id}</IdDetailsContainer>
            <SummaryContainer>
              <SummarySubtitle>
                {t(
                  'smartFunctionDetailsPage.riskReliefRootCause.summarySubtitle'
                )}
              </SummarySubtitle>
              {row.rootCauseSummary}
            </SummaryContainer>
            <ToggleDetailsContainer>
              <InclusionSwitch
                checked={checkForExclusion(record, row)}
                onClick={() => handleDocumentSwitchClick(record, row)}
                loading={false}
                disabled={disableInclusionSwitches}
              />
            </ToggleDetailsContainer>
          </ExpandedSectionRow>
        ))}
    </ExpandedSection>
  )

  const columns: ColumnsType<RootCauseMainRow> = [
    {
      title: t('smartFunctionDetailsPage.riskReliefRootCause.tableTitles.id'),
      dataIndex: 'id',
      key: 'id',
      width: '40%',
      render: (_: string, record: RootCauseMainRow) => {
        return {
          children: (
            <MainLabelContainer>
              {t(
                'smartFunctionDetailsPage.riskReliefRootCause.tableMainLabel.part1'
              )}
              <BadgeContainer>
                <Badge
                  color={BadgeColors.ROOT_CAUSE_DISCOVERY}
                  icon={<BugOutlined />}
                >
                  {record.tag ||
                    t(
                      'smartFunctionDetailsPage.riskReliefRootCause.noRootCause'
                    )}
                </Badge>
              </BadgeContainer>
              {t(
                'smartFunctionDetailsPage.riskReliefRootCause.tableMainLabel.part3',
                { count: record.count, total: record.total }
              )}
            </MainLabelContainer>
          ),
          props: { colSpan: 2 },
        }
      },
    },
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefRootCause.tableTitles.summary'
      ),
      dataIndex: 'summary',
      key: 'summary',
      width: '40%',
      render: (_: string, record: RootCauseMainRow) => {
        return {
          props: { colSpan: 0 },
        }
      },
    },
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefRootCause.tableTitles.inclusion'
      ),
      dataIndex: 'inclusion',
      key: 'inclusion',
      width: '20%',
      render: (_: string, record: RootCauseMainRow) => (
        <TableBoldColumnContainer>
          <ToggleMainContainer>
            <InclusionSwitch
              checked={record.details.every((row) =>
                checkForExclusion(record, row)
              )}
              onClick={() => handleRootCauseInclusionSwitchClick(record)}
              disabled={disableInclusionSwitches}
              loading={false}
            />
          </ToggleMainContainer>
        </TableBoldColumnContainer>
      ),
    },
  ]

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SmartFunctionListPanelLayout>
      <ExpandableTable
        dataSource={formattedData}
        columns={columns}
        expandedRowRender={expandedRowRender}
        tableClass="table-no-padding-expanded-section"
        rowClassName="smart-function-expandable-model"
      />
    </SmartFunctionListPanelLayout>
  )
}

export default RiskReliefRootCauseDiscoverySmartFunctionDetailsPagePanel
